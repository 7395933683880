export default {
  Wrapper:          defineAsyncComponent(() => import('./Wrapper.vue')),
  GetAsset:         defineAsyncComponent(() => import('./GetAsset/index.vue')),
  GetAssetQA:       defineAsyncComponent(() => import('./GetAssetQA/index.vue')),
  BecomePartner:    defineAsyncComponent(() => import('./BecomePartner.vue')),
  ContactUs:        defineAsyncComponent(() => import('./ContactUs.vue')),
  RequestDemo:      defineAsyncComponent(() => import('./RequestDemo.vue')),
  RequestDemoQA:    defineAsyncComponent(() => import('./RequestDemoQA.vue')),
  RequestDemoVSA:   defineAsyncComponent(() => import('./RequestDemoVSA.vue')),
  RequestDemoShort: defineAsyncComponent(() => import('./RequestDemoShort.vue')),
  RequestBundle:    defineAsyncComponent(() => import('./RequestBundle.vue')),
  GetFreeDemo:      defineAsyncComponent(() => import('./GetFreeDemo.vue')),
  GDPR:             defineAsyncComponent(() => import('./GDPR/index.vue')),
};
